import React from "react";
import { Container, Row } from "react-bootstrap";
import { Section, Title } from "../../components/Core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import 'swiper/css';
import 'swiper/css/autoplay';

const PlaceHolder = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
`;

const SwiperStyled = styled(Swiper)`
    .swiper-wrapper{
    transition-timing-function:linear !important; 
    }
`;

const Clients = () => (
    <Section className="pt-0 pb-5">
        <Container fluid>
            <Row>
                <Title variant="secSm" className="mb-5 text-center">Brand Collaborations</Title>
                <SwiperStyled
                    modules={[Autoplay]}
                    slidesPerView={2}
                    grabCursor={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false
                    }}
                    loop={true}
                    freeMode={true}
                    freeModeMomentum={false}
                    speed={4000}
                    breakpoints={{
                        "640": {
                            "slidesPerView": 3
                        },
                        "768": {
                            "slidesPerView": 4
                        },
                        "1024": {
                            "slidesPerView": 6
                        },
                        "1920": {
                            "slidesPerView": 8
                        }
                    }}>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/liberty-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/buff-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/raceface-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/forbidden-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/toyota-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/momentum-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/giro-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/line-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/swany-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/gopro-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/orage-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/oakley-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/stoko-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/auclair-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/mec-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/fc-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/fbc-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/mackenzie-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/xspex-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/fis-logo.jpg" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                    <SwiperSlide>
                        <PlaceHolder>
                            <StaticImage src="../../assets/image/clients/rocky-logo.png" width={150} />
                        </PlaceHolder>
                    </SwiperSlide>

                </SwiperStyled>
            </Row>
        </Container>
    </Section>
);

export default Clients;

import React from "react";
import styled from "styled-components";

const Video = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
`

const VimeoIframe = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  min-height: 100vh;
`;

const VideoLoop = ({
  id,
  width = 16,
  height = 9,
  initialState = false,
  ...rest
}) => {
  if (!id) return null

  return (
    <Video {...rest}>
      <VimeoIframe
        src={`https://player.vimeo.com/video/${id}?background=1&autoplay=1&mute=1&autopause=0&loop=1`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        style={{
          height: `${(height / width) * 100}vw`,
          minWidth: `${(width / height) * 100}vh`,
        }}
      ></VimeoIframe>
    </Video>
  )
}

export default VideoLoop
import loveTheRide from "../assets/image/thumbnails/love-the-ride.jpg";
import homegrown from "../assets/image/thumbnails/homegrown.jpg";
import aGoodDay from "../assets/image/thumbnails/a-good-day.jpg";
import liberty from "../assets/image/thumbnails/liberty.jpg";
import giro from "../assets/image/thumbnails/giro.jpg";
import orage from "../assets/image/thumbnails/orage.jpg";
import auclair from "../assets/image/thumbnails/auclair.jpg";
import zayn from "../assets/image/thumbnails/zayn.jpg";
import darrah from "../assets/image/thumbnails/summer-throwdown.jpg";
import stoko from "../assets/image/thumbnails/stoko.jpg";
import gopro from "../assets/image/thumbnails/gopro.jpg";
import fis from "../assets/image/thumbnails/fis.jpg";
import oakley from "../assets/image/thumbnails/oakley.jpg";
import xspex from "../assets/image/thumbnails/xspex.jpg";
import mec from "../assets/image/thumbnails/mec.jpg";
import mackenzie from "../assets/image/thumbnails/better-together.jpg"
import tra from "../assets/image/thumbnails/tra.jpg";
import momentum from "../assets/image/thumbnails/momentum.jpg";

export const work = [
    {
        brand: `Rocky Mountain`,
        slug: `love-the-ride`,
        title: `Love the Ride`,
        categories: [
            `videography`, `production`, `photography`
        ],
        thumbnail: loveTheRide,
        link: "/love-the-ride"
    },
    {
        brand: `BUFF`,
        slug: `homegrown`,
        title: `Homegrown with Maïa Schwinghammer`,
        categories: [
            `photography`, `production`
        ],
        thumbnail: homegrown,
        link: "/homegrown"
    }, {
        brand: `Race Face, Forbidden Bike`,
        slug: `a-good-day`,
        title: `A Good Day`,
        categories: [
            `videography`, `production`, `photography`
        ],
        thumbnail: aGoodDay,
        link: "/a-good-day"
    }, {
        brand: `Liberty Skis`,
        slug: `liberty-skis`,
        title: `Line-Up Launch`,
        categories: [
            `photography`, `videography`
        ],
        thumbnail: liberty,
        link: "/liberty-skis"
    }, {
        brand: `Giro`,
        slug: `giro-summer`,
        title: `Giro Summer`,
        categories: [`photography`],
        thumbnail: giro,
        link: "/giro-summer"
    }, {
        brand: `Orage`,
        slug: `innovation-through-style`,
        title: `Innovation Through Style`,
        categories: [`photography`],
        thumbnail: orage,
        link: "/innovation-through-style"
    }, {
        brand: `Toyota Canada`,
        slug: `the-road-ahead`,
        title: `The Road Ahead`,
        categories: [
            `production`, 'videography', 'photography'
        ],
        thumbnail: tra,
        link: "/the-road-ahead"
    }, {
        brand: `Auclair Sports`,
        slug: `canadian-thumbs-up`,
        title: `Canadian Thumbs Up`,
        categories: [
            `photography`, `videography`
        ],
        thumbnail: auclair,
        link: "/canadian-thumbs-up"
    }, {
        brand: `686`,
        slug: `summer-throwdown`,
        title: `Summer Throwdown`,
        categories: [
            `videography`, 'photography'
        ],
        thumbnail: darrah,
        link: "/summer-throwdown"
    }, {
        brand: `Stoko`,
        slug: `the-future-of-knee-braces`,
        title: `The Future of Knee Braces`,
        categories: [`photography`],
        thumbnail: stoko,
        link: "/the-future-of-knee-braces"
    }, {
        brand: `GoPro`,
        slug: `hero-10`,
        title: `Hero 10`,
        categories: [`photography`],
        thumbnail: gopro,
        link: "/hero-10"
    }, {
        brand: `FIS Freestyle`,
        slug: `world-cup-photography`,
        title: `World Cup Photography`,
        categories: [`photography`],
        thumbnail: fis,
        link: "/world-cup-photography"
    }, {
        brand: `Oakley`,
        slug: `be-who-you-are`,
        title: `Be Who You Are`,
        categories: [`photography`],
        thumbnail: oakley,
        link: "/be-who-you-are"
    }, {
        brand: `XSPEX`,
        slug: `goggle-launch`,
        title: `Pro Model Goggle Launch`,
        categories: [`photography`],
        thumbnail: xspex,
        link: "/goggle-launch"
    }, {
        brand: `MEC`,
        slug: `dawn-to-dusk`,
        title: `Dawn to Dusk`,
        categories: [`photography`],
        thumbnail: mec,
        link: "/dawn-to-dusk"
    }, {
        brand: `Momentum Camps`,
        slug: `best-summer-ever`,
        title: `Best Summer Ever`,
        categories: [`production`, `photography`],
        thumbnail: momentum,
        link: "/best-summer-ever"
    }, {
        brand: `Mackenzie Investments`,
        slug: `better-together`,
        title: `Better Together`,
        categories: [
            `production`, `photography`
        ],
        thumbnail: mackenzie,
        link: "/better-together"
    }
];